import React, { useContext } from "react";
import { catCategoriesKeys, catCategories } from "../constants/catCategories";
import {
  catStylesKeys,
  catStyles,
  getStyleImage
} from "../constants/catStyles";
import CatCategory from "../components/CatCategory";
import useFilterMenu from "../hooks/use-filter-menu";
const replaceNames = {
  press: "PRESS / BOOKS"
};

export default ({ onMouseOut }) => {
  const { category, style, pickCategory, pickStyle } = useFilterMenu();
  const onClickCategory = e => {
    pickCategory(e.currentTarget.dataset["cat"]);
  };
  const onClickStyle = e => {
    pickStyle(e.currentTarget.dataset["style"]);
  };
  return (
    <div onMouseLeave={onMouseOut} className="filterContentWrapper">
      <div className="filterContent">
        <h3 className="filterTitle">FILTERS</h3>
        <ul className="filterWrapper">
          {catCategoriesKeys
            .filter(key => key !== "books")
            .map(key => {
              const currentClass = category === key ? " current" : "";
              return (
                <li
                  className={"unFiltro filtro-" + key + currentClass}
                  key={key}
                  data-cat={key}
                  onClick={onClickCategory}
                >
                  <CatCategory
                    categoryKey={key}
                    horizontal
                    label={replaceNames[key] || catCategories[key]}
                  />
                </li>
              );
            })}
        </ul>

        <h3 className="filterTitle nextTitles">STYLES</h3>
        <ul className="filterWrapper">
          {catStylesKeys.map(key => {
            const currentClass = style === key ? " current" : "";
            return (
              <li
                className={"unFiltro unFiltroStyle style-" + key + currentClass}
                key={key}
                data-style={key}
                onClick={onClickStyle}
              >
                <i className="">
                  <img src={getStyleImage(key)} alt={catStyles[key]} />{" "}
                </i>
                <span className="nameFilter">{catStyles[key]}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
