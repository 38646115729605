export const catStyles = {
    "linear": "LINEAR",
    "isometric": "ISOMETRIC",
    "flat": "FLAT",
    "lowPoly": "LOW POLY",
    "gradients": "GRADIENTS",
}

export const catStylesKeys = Object.keys(catStyles)

export const getCategoryName = keyStyle => catStyles[keyStyle] || ""

import gradients from "../img/icons/styles/gradients.svg"
import flat from "../img/icons/styles/flat.svg"
import lowPoly from "../img/icons/styles/low-poly.svg"
import isometric from "../img/icons/styles/isometric.svg"
import linear from "../img/icons/styles/linear.svg"

export const getStyleImage = (keyStyle, white=false) => {
    const imagesDefault = {
        "gradients": gradients,
        "flat": flat,
        "lowPoly": lowPoly,
        "isometric": isometric,
        "linear": linear,
    }
    const imagesWhite = {
        ...imagesDefault
    }
    return white?imagesWhite[keyStyle]:imagesDefault[keyStyle]
}