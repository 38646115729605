import React from "react";

import imgBehance from "../img/icons/rrss/behance.svg";
import imgDomestica from "../img/icons/rrss/domestica.svg";
import imgDribble from "../img/icons/rrss/dribble.svg";
import imgFacebook from "../img/icons/rrss/facebook.svg";
import imgInstagram from "../img/icons/rrss/instagram.svg";

export default ({ onMouseOut }) => {
  return (
    <div onMouseLeave={onMouseOut} className="redesContentWrapper">
      <div className="redesContent">
        <h3 className="redesTitle">RRSS</h3>
        <ul className="redesWrapper">
          <li className="behance unaRed">
            <a href="https://www.behance.net/mauco" target="_blank no-referer">
              <i className="fas fa-behance">
                <img src={imgBehance} alt="logo BEHANCE" />
              </i>
              <span className="nameRed">BEHANCE</span>
            </a>
          </li>
          <li className="instagram unaRed">
            <a
              href="https://www.instagram.com/maucosis/"
              target="_blank no-referer"
            >
              <i className="fas fa-instagram">
                <img src={imgInstagram} alt="logo INSTAGRAM" />
              </i>
              <span className="nameRed">INSTAGRAM</span>
            </a>
          </li>
          <li className="facebook unaRed">
            <a
              href="https://www.facebook.com/maucosis/"
              target="_blank no-referer"
            >
              <i className="fas fa-facebook">
                <img src={imgFacebook} alt="logo FACEBOOK" />
              </i>
              <span className="nameRed">FACEBOOK</span>
            </a>
          </li>
          <li className="domestica unaRed">
            <a
              href="https://www.domestika.org/es/mauco/portfolio"
              target="_blan no-referer"
            >
              <i className="fas fa-domestica">
                <img src={imgDomestica} alt="logo DOMESTICA" />
              </i>
              <span className="nameRed">DOMESTICA</span>
            </a>
          </li>
          <li className="dribbble unaRed">
            <a href="https://dribbble.com/maucosis" target="_blank no-referer">
              <i className="fas fa-dribbble">
                <img src={imgDribble} alt="logo DRIBBBLE" />
              </i>
              <span className="nameRed">DRIBBBLE</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
