import React from "react";
import { getCategoryName, getCategoryImage } from "../constants/catCategories";
export default ({ categoryKey, white, horizontal, label }) => {
  const image = getCategoryImage(categoryKey, white);
  const name = getCategoryName(categoryKey);
  return (
    <div className={(horizontal ? "catHorizontal" : "") + " catCategory"}>
      <img src={image} alt={name} />
      <div className="nameCategory" data-cat={categoryKey}>
        {label || name}
      </div>
    </div>
  );
};
