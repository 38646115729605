import React, { Component } from "react";
import Link from "gatsby-link";
import RedesContent from "../components/RedesContent";
import FilterContent from "../components/FilterContent";
import logo from "../img/logo-mauco-sosa.svg";

const showCurrentItem = (path = "", pathToCheck) => {
  return pathToCheck === path || path.includes(pathToCheck)
    ? " current-item"
    : "";
};
class Navbar extends Component {
  state = {
    classHovers: ""
  };
  mouseOverGeneric = nameClass => () => {
    this.setState(state => {
      state.classHovers = nameClass;
      return state;
    });
  };
  mouseOutGeneric = () => {
    this.setState({ classHovers: "" });
  };
  redes = {
    mouseOver: this.mouseOverGeneric("redesHover"),
    mouseOut: this.mouseOutGeneric
  };
  filter = {
    mouseOver: this.mouseOverGeneric("filterHover"),
    mouseOut: this.mouseOutGeneric
  };
  render() {
    const { location } = this.props;
    const { classHovers } = this.state;
    return (
      <div className={"navAndPanels  " + classHovers}>
        <nav className="navbar is-transparent">
          <div className="container">
            <div className="navbar-brand">
              <Link to="/work" className="navbar-item">
                <figure className="image">
                  <img
                    src={logo}
                    alt="LOGO MAUCO SOSA"
                    style={{ width: "261px" }}
                  />
                </figure>
              </Link>
            </div>
            <div className="navbar-end">
              <Link
                className={
                  "navbar-item" + showCurrentItem(location.pathname, "/work")
                }
                to="/work"
              >
                WORK
              </Link>
              <Link
                className={
                  "navbar-item" + showCurrentItem(location.pathname, "/artlab")
                }
                to="/artlab"
              >
                ART LAB
              </Link>
              <a
                href="https://www.domestika.org/es/courses/141-ilustracion-corporativa-guia-de-estilo-vectorial"
                target="_blank"
                rel="noopener noreferrer"
                className="navbar-item"
              >
                CLASS
              </a>
              <Link
                className={
                  "navbar-item" + showCurrentItem(location.pathname, "/about")
                }
                to="/about"
              >
                ABOUT
              </Link>

              {location.pathname === "/work" && (
                <div className="navbar-item" style={{ width: "77px" }}>
                  <div
                    className="filterIconWrapper"
                    onMouseOver={this.filter.mouseOver}
                  >
                    <i className="filterIcon">
                      <div className="filterIconMidBars" />
                      <div className="filterIconMidBars" />
                      <div className="filterIconMidBars" />
                    </i>
                  </div>
                </div>
              )}

              <div className="navbar-item noline" style={{ width: "58px" }}>
                <div
                  className="redesIconWrapper"
                  onMouseOver={this.redes.mouseOver}
                >
                  <i className="redesIcon">
                    <div className="redesIconSmallCircle" />
                    <div className="redesIconSmallCircle" />
                    <div className="redesIconSmallCircle" />
                    <div className="redesIconBigCircle" />
                    <div className="redesIconLine" />
                    <div className="redesIconLine" />
                    <div className="redesIconLine" />
                  </i>
                </div>
              </div>
            </div>
            {location.pathname === "/work" && (
              <FilterContent onMouseOut={this.filter.mouseOut} />
            )}
            <RedesContent onMouseOut={this.redes.mouseOut} />
          </div>
        </nav>
      </div>
    );
  }
}
export default Navbar;
