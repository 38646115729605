import window from "global";
import React from "react";
import Helmet from "react-helmet";

import Navbar from "./Navbar";
import "./all.sass";

const Layout = ({ children, location, hideNavBar = false }) => {
  const locationSafe = location || { pathname: "", ...window.location };
  return (
    <div>
      <Helmet title="MAUCO SOSA - Illustrator, Art Director in Madrid">
        <html lang="en" className="debug" />
      </Helmet>
      {"/" === locationSafe.pathname || hideNavBar ? null : (
        <Navbar location={locationSafe} />
      )}
      <div className="bodyContent">{children}</div>
    </div>
  );
};

export default Layout;
