import imgAdvertisingWhite from "../img/icons/advertising-white.svg";
import imgAdvertising from "../img/icons/advertising.svg";
import imgCorporateWhite from "../img/icons/corporate-white.svg";
import imgCorporate from "../img/icons/corporate.svg";
import imgInfographicWhite from "../img/icons/infographic-white.svg";
import imgInfographic from "../img/icons/infographic.svg";
import imgPersonalWhite from "../img/icons/personal-white.svg";
import imgPersonal from "../img/icons/personal.svg";
import imgPressWhite from "../img/icons/press-white.svg";
import imgPress from "../img/icons/press.svg";

export const catCategories = {
  advertising: "Advertising",
  corporate: "Corporate Illustration",
  infographic: "Infographic",
  personal: "Personal Work",
  press: "Press",
  books: "Books"
};

export const catCategoriesKeys = Object.keys(catCategories);

export const getCategoryName = keyCategory => catCategories[keyCategory] || "";

export const getCategoryImage = (keyCategory, white = false) => {
  const imagesDefault = {
    advertising: imgAdvertising,
    corporate: imgCorporate,
    infographic: imgInfographic,
    personal: imgPersonal,
    press: imgPress,
    books: imgPress
  };
  const imagesWhite = {
    advertising: imgAdvertisingWhite,
    corporate: imgCorporateWhite,
    infographic: imgInfographicWhite,
    personal: imgPersonalWhite,
    press: imgPressWhite,
    books: imgPressWhite
  };
  return white ? imagesWhite[keyCategory] : imagesDefault[keyCategory];
};
